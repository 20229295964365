/*
|--------------------
|     PAGE HOME
|--------------------
*/

#video-background {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
}

#page-home {
  .banner {
    overflow: hidden;
    // min-height: 83vh;
    // z-index:10;
  }
  h1 {
    font-weight: 500;
    font-size: 65px;
    line-height: 70px;
    text-shadow: #f1f1f1 0 0 4px;
    text-transform: uppercase;
    color:white;
    span{
      color:#f9b300;
    }
    @include media-breakpoint-down(md) {
      font-size: 40px;
      line-height: 46px;
    }
  }
  .introhome {
    color: white;
    font-family: Arial;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    border-top : 1px solid #f9b300;
    border-bottom : 1px solid #f9b300;
    padding:20px 0;
    text-align: center;

    a{
      color : #f9b300;
      display:block;
    }
  }
  .down {
    background-image: url('../img/icon/arrow.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 25px;
    height: 50px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    display: block;
  }
  .newslist {
    h3 {
      position: relative;
      @include media-breakpoint-up(lg) {
        &:before {
          content: "";
          height: 100%;
          width: 2px;
          background-color: $yellow;
          display: block;
          position: absolute;
          left: -15px;
          top: 0px;
        }
      }
    }
  }

  img {
  }

  #section1 {
    background-position: center;
    background-size: contain;
  }

  #section7 {
    h2 {
      margin-left: 65px;
      @include media-breakpoint-down(md) {
        margin-left: 0;
      }
    }
    #clients-slider {
      //box-shadow: 0 0 5px 1px #eaeaea;
      background-color: #ffffff;
      .item {
        height: 210px;
        display: flex;
        vertical-align: middle;
        align-items: center;
        & > div {
          margin: auto;
          .image {
            margin: 15px;
            height: 110px;
            img {
              height: 60px;
              max-width: inherit;
              width: inherit;
              filter: grayscale(100%);
              @include media-breakpoint-down(lg) {
                height: 47px;
              }
            }
          }
          .desc-cli {
            color: #cccccc;
            font-family: Arial;
            font-size: 10px;
            letter-spacing: 0.83px;
            text-transform: uppercase;
            text-align: center;
          }
        }
      }
    }
    .swip-cli-pag {
      position: absolute;
      bottom: 0;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      .swiper-cli-pagination {
        float: left;
        position: relative;
        margin-top: 4px;
        .swiper-pagination-bullet {
          background-color: #d9dadb;
          opacity: initial;
          width: 50px;
          height: 2px;
          border-radius: initial;
          margin-right: 4px;
        }
        .swiper-pagination-bullet-active {
          background-color: $yellow;
        }
      }
      .swiper-cli-btn-prev {
        background-image: url('../img/icon/mini-arrow2.png');
        height: 40px;
        width: 40px;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        float: left;
        outline: none;
        @include media-breakpoint-down(md) {
          position: absolute;
          left: 0;
          top: -120px;
          z-index: 1;
        }
      }
      .swiper-cli-btn-next {
        background-image: url('../img/icon/mini-arrow2.png');
        height: 40px;
        width: 40px;
        transform: rotate(180deg);
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        float: left;
        outline: none;
        @include media-breakpoint-down(md) {
          position: absolute;
          right: 30px;
          top: -120px;
          z-index: 1;
        }
      }
    }
    .his-img {
      position: absolute;
      top: -50px;
      @include media-breakpoint-down(lg) {
        top: 130px;
      }
      @include media-breakpoint-down(md) {
        top: 0px;
        position: relative;
        margin-top: 40px;
      }
    }
  }

  .newslist h2 {
    color: #f9b300;
    font-family: "Frutiger";
    font-size: 50px;
    font-weight: 900;
    line-height: 54px;
    span {
      color: #fff;
    }
    &:before {
      content: "";
      height: 1px;
      width: 55px;
      background-color: #f9b300;
      display: block;
      position: absolute;
      left: -65px;
      top: 26px;
    }
  }
  .newslist h3 {
    font-family: "Frutiger";
    font-size: 15px;
    font-weight: 900;
    line-height: 22px;
    color: #f9b300;
    position: relative;
  }

}

.seealls {
  color: #ffffff;
  font-family: "Arial";
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  float: right;
  position: relative;
  &:after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: $yellow;
    display: block;
  }
  @include media-breakpoint-down(md) {
    float: initial;
  }
}

.nohomesticky {
  display: block;
}

.homesticky {
  display: none;
}

.sticky {
  .nohomesticky {
    display: none;
  }
  .homesticky {
    display: block;
  }
}

h2 {
  position: relative;
  @include media-breakpoint-up(lg) {
    &:before {
      content: "";
      height: 1px;
      width: 55px;
      background-color: $blue;
      display: block;
      position: absolute;
      left: -65px;
      top: 26px;
    }
  }
}

.bg-blue {
  h2 {
    position: relative;
    @include media-breakpoint-up(lg) {
      &:before {
        content: "";
        background-color: white;
      }
    }
  }
}

#homeswiper {
  .swiper-slide {
    @include media-breakpoint-up(lg) {
      display: flex;
      vertical-align: middle;
      align-items: center;
    }
    .image {
      float: left;
      width: 50%;
      margin-right: 90px;
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-right: 0px;
        img {
          height: 250px;
          width: initial;
          margin: auto;
          display: block;

          @include media-breakpoint-down(md) {
            width: 100%;
            height: initial;
          }
        }
      }
    }
    .desc {
      color: #ffffff;
      font-family: "Arial";
      font-size: 16px;
      line-height: 26px;
      width: 50%;
      float: left;
      @include media-breakpoint-down(md) {
        width: 100%;
        text-align: center;
        display: grid;
      }
      h3 {
        color: #ffffff;
        font-family: "Frutiger";
        font-size: 30px;
        font-weight: 900;
        line-height: 36px;
        span {
          color: $yellow;
        }
      }
      .btn {
        background-color: #fff;
        border-color: #fff;
        color: $blue;
        &:hover {
          background-color: $blue;
          border-color: #fff;
          color: #fff;
        }
      }
    }
    .swiper-pagination {
      float: left;
      position: relative;
      margin-top: 4px;
      .swiper-pagination-bullet {
        background-color: #59627f;
        opacity: initial;
        width: 50px;
        height: 2px;
        border-radius: initial;
        margin-right: 4px;
      }
      .swiper-pagination-bullet-active {
        background-color: $yellow;
      }
    }
    .swiper-btn-prev {
      background-image: url('../img/icon/mini-arrow.png');
      height: 40px;
      width: 40px;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      float: left;
      @include media-breakpoint-down(md) {
        position: absolute;
        left: 0;
      }
    }
    .swiper-btn-next {
      background-image: url('../img/icon/mini-arrow.png');
      height: 40px;
      width: 40px;
      transform: rotate(180deg);
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      float: left;
      @include media-breakpoint-down(md) {
        position: absolute;
        right: 0;
      }
    }
  }
}

#sticky-area{
  display: none;
  position: absolute;
  top: 30px;
  left:25%;
  width: 50%;
  margin:auto;
  z-index:15 !important;

  // background:rgba(0,0,0,0.5);

  a{
    color:white;
    font-family: Arial;
    font-weight: 400;
    font-size: 20px;
    text-align:center;
    font-style: italic;
  }

  #sticky-slider {

    .swiper-container {
      width: 100%;
      height: 100%;
      display: flex;
      text-align: center;
    }
  
    .swiper-slide {
      text-align: center;
      display:flex;
      justify-content: center;
      align-items:center;
    }
  
    .swiper-pagination {
      position: relative;
      text-align: center;
      margin-top: 4px;

      .swiper-pagination-bullet {
        background-color: white;
        opacity: initial;
        width: 50px;
        height: 8px;
        border-radius: initial;
        margin-right: 8px;
      }
      .swiper-pagination-bullet-active {
        background-color: $yellow;
      }
    }
  
    // .swiper-btn-prev {
    //   background-image: url('../img/icon/mini-arrow.png');
    //   height: 40px;
    //   width: 40px;
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   cursor: pointer;
    //   float: left;
    //   @include media-breakpoint-down(md) {
    //     position: absolute;
    //     left: 0;
    //   }
    // }
    // .swiper-btn-next {
    //   background-image: url('../img/icon/mini-arrow.png');
    //   height: 40px;
    //   width: 40px;
    //   transform: rotate(180deg);
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   cursor: pointer;
    //   float: left;
    //   @include media-breakpoint-down(md) {
    //     position: absolute;
    //     right: 0;
    //   }
    // }
    
  }
}

@media only screen and (min-width: 1026px) {
  #sticky-area{
    display:block;
  }
}

