/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  background-color: $blue;
  @include media-breakpoint-down(md) {
    padding: 20px 0;
  }
  .logo-ft {
    img{
      width: 131px;
    }
    @include media-breakpoint-down(md) {
      margin: auto;
      display: block;
      text-align: center;
    }
  }
  .menu-ft, .menu-ft a {
    color: #ffffff;
    font-family: "Frutiger";
    font-size: 14px;
    font-weight: 400;
    padding-top: 11px;
    text-align: left;
    @include media-breakpoint-down(md) {
      text-align: center;
    }
    a {
      padding-right: 30px;
    }
  }
  .copy-ft, .copy-ft a {
    color: #ffffff;
    font-family: "Frutiger";
    font-size: 14px;
    font-weight: 400;
    padding-top: 11px;
    text-align: right;
    @include media-breakpoint-down(md) {
      text-align: center;
    }
    .gras{
      font-weight: 700;
    }
  }
}