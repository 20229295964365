/*
|--------------------
|  Smooth Scrollbar
|--------------------
*/

/*
|
| Scroll area
|--------------
*/
.scroll-area {
    .scrollbar-track {
        &.scrollbar-track-y {
            width: 4px !important;

            .scrollbar-thumb {
                width: 100% !important;
                background: $black !important;
            }
        }
    }
}
