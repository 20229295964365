/*
|--------------------
|    NOS CLIENTS
|--------------------
*/

#page-nos-clients,
#page-notre-histoire {
  width: 100%;
  .intro {
    position: relative;
    vertical-align: middle;
    align-items: center;
    background-size: cover;
    background-position: center;
    h1 {
      margin-left: 65px;
      @include media-breakpoint-up(lg) {
        &:before {
          content: "";
          height: 1px;
          width: 55px;
          background-color: white;
          display: block;
          position: absolute;
          left: 15px;
          top: 40px;
        }
      }

      @include media-breakpoint-down(md) {
        text-align: center;
        margin-left: 0;
      }
    }
    .container {
      min-height: 500px;
    }
  }
  .desc {
    color: white;
    font-family: Arial;
    font-size: 16px;
    line-height: 26px;
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
  .back-link {
    &:hover {
      color: $yellow;
    }
  }
  h1.title-xl {
    color: white;
    font-family: "Frutiger";
    font-size: 64px;
    font-weight: 900;
    line-height: 56px;
    @include media-breakpoint-down(md) {
      font-size: 32px;
      line-height: initial;
    }
    @include media-breakpoint-down(sm) {
      font-size: 30px;
      line-height: initial;
    }
    span {
      color: $yellow;
    }
  }

  #section-clients {
    background-color: #fcfcfc;
    .nos-clients {
      .lien-client {
        display: block;
      }
      .item {
        position: relative;
        background-color: white;
        height: 300px;
        width: 100%;
        padding: 15px;
        box-shadow: 0 0 13px 2px rgba(0, 0, 0, 0.02);
        margin-bottom: 30px;
        display: flex;
        vertical-align: middle;
        align-items: center;
        &:hover {
          img {
            width: 190px;
            transition: all 0.4s ease;
            filter: initial;
          }
        }
        @include media-breakpoint-down(lg) {
          height: 210px;
        }
        img {
          width: 180px;
          margin: auto;
          position: absolute;
          left: 0;
          right: 0;
          transition: all 0.4s ease;
          filter: grayscale(100%);

        }
      }
      .categorie {
        position: absolute;
        bottom: 7px;
        left: 0;
        right: 0;
        text-align: center;
        color: #cccccc;
        font-family: Arial;
        font-size: 10px;
        letter-spacing: 0.83px;
        text-transform: uppercase;
        &:before {
          content: "";
          height: 1px;
          width: 20px;
          background-color: #cccccc;
          display: block;
          margin: auto;
          margin-bottom: 5px;
        }
      }
    }
  }

  .cat-clients {
    text-align: center;
    position: absolute;
    top: -36px;
    left: 0;
    right: 0;
    @include media-breakpoint-down(md) {
      top: -60px;
    }
    @include media-breakpoint-down(sm) {
      top: -90px;
    }
    @include media-breakpoint-down(xs) {
      top: -120px;
    }
    .fil-cat {
      color: #ffffff;
      font-family: Arial;
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      cursor: pointer;
      margin-right: 35px;
      position: relative;
      @include media-breakpoint-down(lg) {
        font-size: 11px;
        margin-right: 8px;
      }
      &.active {
        &:before {
          content: "";
          background-color: #fff;
          height: 3px;
          width: 100%;
          position: absolute;
          bottom: -10px;
          @include media-breakpoint-down(lg) {
            bottom: -11px;
          }
          @include media-breakpoint-down(md) {
            bottom: -3px;
          }
        }
      }
    }
  }

}