/*
|--------------------
|       Contact
|--------------------
*/

#page-contact {
  background-color: #0e1a49;
  width: 100%;
  .intro {
    position: relative;
    display: flex;
    vertical-align: middle;
    align-items: center;
    background-size: cover;
    background-position: center;
    h1 {
      margin-left: 65px;
      @include media-breakpoint-up(lg) {
        &:before {
          content: "";
          height: 1px;
          width: 55px;
          background-color: white;
          display: block;
          position: absolute;
          left: 15px;
          top: 40px;
        }
      }

      @include media-breakpoint-down(md) {
        text-align: center;
        margin-left: 0;
      }
    }
  }
  .desc {
    color: white;
    font-family: Arial;
    font-size: 16px;
    line-height: 26px;
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
  .back-link {
    &:hover {
      color: $yellow;
    }
  }

  h1.title-xl {
    color: white;
    font-family: "Frutiger";
    font-size: 64px;
    font-weight: 900;
    line-height: 64px;
    @include media-breakpoint-down(md) {
      font-size: 32px;
      line-height: initial;
    }
    @include media-breakpoint-down(sm) {
      font-size: 30px;
      line-height: initial;
    }
    span {
      color: $yellow;
    }
  }
  .section-map {
    #map {
      position: relative;
      width: 100%;
      height: 600px;
      background-color: $black;
    }
  }
  .txt {
    color: #ffffff;
  }
  .contact-form {
    .consent {
      margin: 15px 0;

      .gfield_label {
        display: none;
      }

      .ginput_container_consent {
        display: flex;

        input {
          width: auto;
          margin: {
            top: 8px;
            right: 20px;
          }
        }

        .gfield_consent_label {
          font-family: $font-family-default;
          color: $white;
          font-size: 16px;
          line-height: 1.8;
          font-weight: 200;
          text-transform: none;
          a {
            color: $white;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .contact-form input[type="submit"]{
    font-family: "Arial";
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    min-width: 230px;
    padding: 20px 20px;
    text-align: center;
    z-index: 2;
    border: 1px solid #fff!important;
    background-color: #fff;
    border-color: #fff;
    color: #0e1a49;
    float: left;
    &:hover{
      background-color: $blue!important;
      border: 1px solid #fff!important;
      color: #fff!important;
    }
  }

  .detailmap {
    @include media-breakpoint-up(lg) {
      margin-top: -25px;
      left: 35px;
      right: 35px;
      position: absolute;
    }
    @include media-breakpoint-down(md) {
      margin: 20px auto;
    }

    .swiper-wrapper {
      .item {
        background-color: #ffffff;
        color: #0e1a49;
        font-family: Arial;
        font-size: 12px;
        letter-spacing: 0.5px;
        line-height: 22px;
        z-index: 1;
        position: relative;
        margin: 0px;
        h2 {
          color: #0e1a49;
          font-family: Arial;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 1px;
          line-height: 26px;
          text-transform: uppercase;
        }
        .coor {
          padding: 27px;
          float: left;
          width: 35%;
          @include media-breakpoint-down(lg) {
            width: initial;
          }
        }
        .adress {
          padding: 20px;
          float: left;
          height: 120px;
          position: relative;
          &:before {
            content: "";
            width: 1px;
            height: 75%;
            background-color: #f9b300;
            position: absolute;
            left: 0;
          }
        }
      }
    }

    .swiper-ct-btn-prev {
      background-image: url('../img/icon/mini-arrow2.png');
      height: 40px;
      width: 40px;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      float: left;
      outline: none;
      position: absolute;
      bottom: 0px;
      right: 40px;
      background-color: #0e1a49;
      z-index: 1;
    }
    .swiper-ct-btn-next {
      background-image: url('../img/icon/mini-arrow2.png');
      height: 40px;
      width: 40px;
      transform: rotate(180deg);
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      float: left;
      outline: none;
      position: absolute;
      bottom: 0px;
      right: 0px;
      background-color: #0e1a49;
      z-index: 1;
    }
  }
}
