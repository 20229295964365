/*
|--------------------
|  NOUS REJOINDRE
|--------------------
*/



#map {
  height: 592px;
  width: 100%;
  display: block;
  padding: 0;
}

.list-offer {
  padding: 0;
  height: 592px;
  overflow: scroll;
  overflow-x: hidden;
  .item-offre {
    padding: 10px 35px 10px 35px;
    border-bottom: 1px solid #e1e2e3;
    position:relative;
    cursor: pointer;
    &:hover {
      background-color: #fcfcfc;
      &:before {
        content: "";
        width: 3px;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0;
        background-color: orange;
      }
    }
    .s6_rejoindre_date {
      color: #a6a6a6;
      font-family: Arial;
      font-size: 12px;
    }
    .s6_rejoindre_post {
      color: #f9b300;
      font-family: "Frutiger";
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 2px;
      line-height: 26px;
      text-transform: uppercase;
    }
    .s6_rejoindre_lieu {
      color: #0e1a49;
      font-family: Arial;
      font-size: 14px;
      line-height: 22px;
    }
    .btn {
      padding: 10px 20px;
      margin: 10px 0;
    }
  }
}