/*
|--------------------
|  NOUS REJOINDRE
|--------------------
*/

#page-notre-histoire {
  #section1 {
    .trigger-link {
      &:hover {
        &:before {
          content: "";
          width: 90px;
          height: 90px;
          left: calc(50% - 45px);
          right: calc(50% - 45px);
          top: calc(50% - 45px);
          transition: all ease 0.4s;
          cursor: pointer;
        }
      }
      img {
        height: 100%;
        width: 100%;
        display: block;
        position: relative;
        cursor: pointer;
      }
      &:before {
        content: "";
        position: absolute;
        background-image: url('../img/icon/play.png');
        width: 80px;
        height: 80px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        left: calc(50% - 40px);
        right: calc(50% - 40px);
        top: calc(50% - 40px);
        transition: all ease 0.4s;
        cursor: pointer;
        z-index: 1;
      }
    }
  }
  #section2 {
    .item {
      @include media-breakpoint-down(md) {
        text-align: center;
      }
      img {
        height: 40px;
        width: initial;
        float: left;
        margin-right: 10px;
        @include media-breakpoint-down(md) {
          float: initial;
          margin-right: initial;
          margin-bottom: 10px;
        }
      }
      .chiffre {
        color: #ffffff;
        font-family: Arial;
        font-size: 48px;
        line-height: 42px;
        font-weight: 700;
        height: 40px;
        @include media-breakpoint-down(md) {
          font-size: 34px;
          line-height: initial;
        }
      }
      .titre {
        color: #ffffff;
        font-family: Arial;
        font-size: 16px;
        line-height: 26px;
        position: relative;
        &:after {
          content: "";
          height: 2px;
          width: 20px;
          background-color: #f9b300;
          display: block;
          position: absolute;
          margin: 5px 0;
          @include media-breakpoint-down(md) {
            margin: 5px auto;
            left: 0;
            right: 0;
          }
        }
      }
      .text {
        color: #a6a6a6;
        font-family: Arial;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
  #section4 {
    h2 {
      margin-left: 65px;
      @include media-breakpoint-down(md) {
        margin-left: 0;
      }
    }
    .img {
      background-size: cover;
      background-repeat: no-repeat;
      height: 180px;
      display: flex;
      vertical-align: middle;
      align-items: center;
      position: relative;
      @include media-breakpoint-down(lg) {
        height: 208px;
      }
      @include media-breakpoint-down(md) {
        height: 280px;
        width: 220px;
        display: flex;
        margin: auto;
      }
      &:before {
        content: "";
        background-color: #0e1a49;
        opacity: 0.3;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all ease 0.3s;
      }
      .detail {
        padding: 10px;
        @include media-breakpoint-down(lg) {
          padding: 5px;
        }
        .id {
          bottom: 15px;
          position: absolute;
          .nom {
            color: #f9b300;
            font-family: "Frutiger";
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            text-transform: uppercase;
            position: relative;
            z-index: 1;
            &:before {
              content: "";
              height: 20px;
              width: 3px;
              display: block;
              background-color: #f9b300;
              float: left;
              margin-right: 15px;
              @include media-breakpoint-down(lg) {
                margin-right: 7px;
              }
            }
          }
          .prenom {
            color: #f9b300;
            font-family: "Frutiger";
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            text-transform: uppercase;
            position: relative;
            z-index: 1;
            &:before {
              content: "";
              height: 20px;
              width: 3px;
              display: block;
              background-color: #f9b300;
              float: left;
              margin-right: 15px;
              @include media-breakpoint-down(lg) {
                margin-right: 7px;
              }
            }
          }
          .role {
            color: #ffffff;
            font-family: Arial;
            font-size: 14px;
            line-height: 22px;
            position: relative;
            z-index: 1;
          }
        }
        .desc {
          display: none;
          opacity: 0;
          transition: all ease 0.3s;
          position: relative;
          z-index: 1;
          font-size: 14px;
          line-height: initial;
          @include media-breakpoint-down(lg) {
            font-size: 13px;
            line-height: 16px;
          }
        }
      }
      &:hover {
        &:before {
          content: "";
          opacity: 0.6;
          transition: all ease 0.3s;
        }
        .desc {
          display: block;
          opacity: 1;
          transition: all ease 0.3s;
        }
        .nom,
        .prenom,
        .role {
          display: none;
          opacity: 0;
          transition: all ease 0.3s;
        }
      }
    }

    .itemcard {
      &:nth-of-type(n + 7) {
        display: none;
      }
      &.all{
        &:nth-of-type(n + 7) {
          display: block;
        }
      }
      .card {
        height: 180px;
        margin: 0 auto;
        position: relative;
        transition: all 0.4s ease-out;
        transform-style: preserve-3d;
        @include media-breakpoint-down(lg) {
          height: 210px;
          width: 100%;
          display: flex;
          margin: auto;
        }
        @include media-breakpoint-down(md) {
          height: 208px;
          width: 100%;
          display: flex;
          margin: auto;
        }
        @include media-breakpoint-down(sm) {
          height: 178px;
        }
        @media(max-width:575px) {
          height: 280px;
          width: 220px;
          margin:auto;
          display: block;
        }
        .front {
          z-index: 2;
          position: absolute;
          width: 100%;
          height: 180px;
          background-size: cover;
          background-repeat: no-repeat;
          backface-visibility: hidden;
          transition: 0.3s all ease-in-out;
          @include media-breakpoint-down(lg) {
            height: 210px;
          }
          @include media-breakpoint-down(md) {
            height: 208px;
          }
          @include media-breakpoint-down(sm) {
            height: 178px;
          }
          @media(max-width:575px) {
            height: 280px;
            width: 220px;
            margin:auto;
            display: block;
          }
          &:before {
            content: "";
            background-color: #0e1a49;
            opacity: 0.3;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transition: all ease 0.3s;
          }
          .detail {
            position: absolute;
            bottom: 10px;
            left: 10px;
            .nom {
              color: #f9b300;
              font-family: "Frutiger";
              font-size: 14px;
              font-weight: 700;
              line-height: 20px;
              text-transform: uppercase;
              position: relative;
              z-index: 1;
              &:before {
                content: "";
                height: 20px;
                width: 3px;
                display: block;
                background-color: #f9b300;
                float: left;
                margin-right: 8px;
              }
            }
            .prenom {
              color: #f9b300;
              font-family: "Frutiger";
              font-size: 14px;
              font-weight: 700;
              line-height: 20px;
              text-transform: uppercase;
              position: relative;
              z-index: 1;
              &:before {
                content: "";
                height: 20px;
                width: 3px;
                display: block;
                background-color: #f9b300;
                float: left;
                margin-right: 8px;
              }
            }
            .role {
              color: #ffffff;
              font-family: Arial;
              font-size: 14px;
              line-height: 22px;
              position: relative;
              z-index: 1;
            }
          }
        }

        .back {
          z-index: 1;
          position: absolute;
          width: 100%;
          height: 180px;
          background-size: cover;
          backface-visibility: hidden;
          transition: 0.3s all ease-in-out;
          transform: rotateY(180deg);
          @include media-breakpoint-down(lg) {
            height: 210px;
          }
          @include media-breakpoint-down(md) {
            height: 208px;
          }
          @include media-breakpoint-down(sm) {
            height: 178px;
          }
          @media(max-width:575px) {
            height: 280px;
            width: 220px;
            margin:auto;
            display: block;
          }
          &:before {
            content: "";
            background-color: #0e1a49;
            opacity: 0.7;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
          }
          .desc {
            color: #ffffff;
            font-family: Arial;
            font-size: 13px;
            line-height: 15px;
            position: relative;
            padding: 15px;
          }
        }
      }
    }

    .card:hover .front {
      transform: rotateY(-180deg);
    }

    .card:hover .back {
      transform: rotateY(0deg);
    }
    .more-team {
      color: #0e1a49;
      cursor: pointer;
      width: max-content;
      margin: auto;
      display: block;
    }
  }
  #section5 {
    h2 {
      margin-left: 65px;
      @include media-breakpoint-down(md) {
        margin-left: 0;
      }
    }
    #clients-slider {
      //box-shadow: 0 0 5px 1px #eaeaea;
      background-color: #ffffff;
      .item {
        height: 210px;
        display: flex;
        vertical-align: middle;
        align-items: center;
        & > div {
          margin: auto;
          .image {
            margin: 15px;
            height: 110px;
            img {
              height: 60px;
              max-width: inherit;
              width: inherit;
              filter: grayscale(100%);
              @include media-breakpoint-down(lg) {
                height: 47px;
              }
            }
          }
          .desc-cli {
            color: #cccccc;
            font-family: Arial;
            font-size: 10px;
            letter-spacing: 0.83px;
            text-transform: uppercase;
            text-align: center;
          }
        }
      }
    }
    .swip-cli-pag {
      position: absolute;
      bottom: 0;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      .swiper-cli-pagination {
        float: left;
        position: relative;
        margin-top: 4px;
        .swiper-pagination-bullet {
          background-color: #d9dadb;
          opacity: initial;
          width: 50px;
          height: 2px;
          border-radius: initial;
          margin-right: 4px;
        }
        .swiper-pagination-bullet-active {
          background-color: $yellow;
        }
      }
      .swiper-cli-btn-prev {
        background-image: url('../img/icon/mini-arrow2.png');
        height: 40px;
        width: 40px;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        float: left;
        outline: none;
        @include media-breakpoint-down(md) {
          position: absolute;
          left: 0;
          top: -120px;
          z-index: 1;
        }
      }
      .swiper-cli-btn-next {
        background-image: url('../img/icon/mini-arrow2.png');
        height: 40px;
        width: 40px;
        transform: rotate(180deg);
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        float: left;
        outline: none;
        @include media-breakpoint-down(md) {
          position: absolute;
          right: 30px;
          top: -120px;
          z-index: 1;
        }
      }
    }
    .his-img {
      position: absolute;
      top: -50px;
      @include media-breakpoint-down(lg) {
        top: 130px;
      }
      @include media-breakpoint-down(md) {
        top: 0px;
        position: relative;
        margin-top: 40px;
      }
    }
  }
  #section6 {
    .item {
      .titre {
        color: #ffffff;
        font-family: "Frutiger";
        font-size: 28px;
        font-weight: 900;
        line-height: 7px;
        @include media-breakpoint-down(md) {
          text-align: center;
          line-height: initial;
        }
        &::first-letter {
          color: #f9b300;
          font-size: 200%;
        }
      }
      .text {
        color: #a6a6a6;
        font-family: Arial;
        font-size: 16px;
        line-height: 26px;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid #a6a6a6;
    }
    hr {
      height: 1px;
      border: none;
      width: 100%;
      background-color: #fff;
      margin-bottom: 20px;
    }
  }
  #section7 {
    h2 {
      color: #0e1a49;
      &:before {
        content: none;
      }
    }
  }
  #section9 {
    .btn {
      color: #0e1a49;
      background-color: #fff;
      &:hover {
        background-color: $blue;
        border-color: #fff;
        color: #fff;
      }
    }
  }

  @include media-breakpoint-down(md) {
    overflow: hidden;
  }
}

#modal-video {
  max-width: initial !important;
  height: 555px !important;
  width: 950px !important;
  @include media-breakpoint-down(md) {
    height: 400px !important;
    width: 700px !important;
  }
  @include media-breakpoint-down(sm) {
    height: 280px !important;
    width: 500px !important;
  }
  @include media-breakpoint-down(xs) {
    height: 166px !important;
    width: 290px !important;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 555px !important;
    width: 950px !important;
    @include media-breakpoint-down(md) {
      height: 400px !important;
      width: 700px !important;
    }
    @include media-breakpoint-down(sm) {
      height: 280px !important;
      width: 500px !important;
    }
    @include media-breakpoint-down(xs) {
      height: 166px !important;
      width: 290px !important;
    }
  }
  .close-modal {
    color: #fff;
    position: absolute;
    top: -25px;
    right: 10px;
    z-index: 9;
    height: 25px;
    width: 35px;
    background: #0e1a49;
    cursor: pointer;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
}

#page-notre-histoire {
  .bg-blue {
    h2 {
      margin-left: 65px;
      @include media-breakpoint-down(md) {
        margin-left: 0;
      }
    }
  }
}

.plyr {
  min-width: 0;
}

.plyr__play-large {
  border: none;
  background-color: #0e1a49;
  padding: 30px;
  cursor: pointer;
}

.plyr--init-hide-controls .plyr__controls {
  opacity: 0;
}

.plyr--stopped .plyr__controls {
  opacity: 0;
}

.player {
  --plyr-color-main: #f9b300;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 0px #eee;
}

