/*
|--------------------
|      HEADER
|--------------------
*/

/*
|
| Header
|---------
*/
.link-menu {
  text-transform: uppercase;
  color: $very-dark-grey;
  font-size: 14px;
  font-weight: 400;
}

#header {
  width: 100%;
  position: fixed;
  z-index: 10;
  transition: all ease 0.8s;
  &.sticky {
    background-color: #0e1a49;
  }
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 25px;

    .item-logo {
      margin: 0 15px;
      img {
        width: 133px;
      }
    }

    .item-nav {
      display: none;

      .item-menu {
        display: flex;

        li {
          &:not(:last-child) {
            margin-right: 20px;
          }

          .item-link {
            @extend .link-menu;

          }
        }
      }

      @include media-breakpoint-up(xl) {
        display: flex;
        align-items: center;

        .item-menu {
          margin-right: 25px;
        }
      }
    }
  }

  .lang-container {
    position: relative;
    display: inline-flex;
    top: 1px;
    @extend .link-menu;
    cursor: pointer;

    ul {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateX(10px);
      transition: opacity 1.2s $easeSmooth, transform 2s $easeSmooth;
    }

    &:hover {
      ul {
        opacity: 1 !important;
        visibility: visible !important;
        transform: translateX(0) !important;
      }
    }
  }

  .btn-menu {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-right: 30px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    &:before {
      content: "";
      background-image: url('../img/icon/menu-c.png');
      height: 76px;
      width: 78px;
      position: absolute;
    }

    & > .item-burger {
      display: block;
      width: 25px;

      & > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: #e8e8e8;
        border-radius: 2px;
        cursor: pointer;

        &:nth-child(2) {
          margin: 6px 0;
        }
      }
    }

    &.btn-menu-b {
      &:before {
        content: "";
        background-image: url('../img/icon/menu-h.png');
      }
      & > .item-burger {
        & > span {
          background-color: #000;
        }
      }
    }

    //@include media-breakpoint-up(xl) {
    //    display: none;
    //}
  }

}

/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: $white;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  .menu-desc {
    background-size: cover;
    background-position: center;
    top: 0;
    bottom: 0;
    height: 100vh;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    .titre-menu {
      font-size: 60px;
      font-weight: 900;
      line-height: 60px;
      color: #0e1a49;
      font-family: "Frutiger";
      position: relative;
      text-transform: uppercase;
      &:before {
        content: "";
        height: 1px;
        width: 55px;
        background-color: #f9b300;
        display: block;
        position: absolute;
        left: -65px;
        top: 26px;
      }
      span{
        color: #f9b300;
      }
    }
  }
  .item-container {
    top: 0;
    bottom: 0;
    height: 100vh;
    display: flex;
    vertical-align: middle;
    align-items: center;

    @extend .container;
    //padding-top: 150px;
    //padding-bottom: 50px;

    ul {
      & > li {
        &:not(:last-child) {
          margin-bottom: 25px;
          @include media-breakpoint-down(md) {
            margin-bottom: 5px;
          }
        }

        a {
          color: #0e1a49;
          font-family: "Frutiger";
          font-size: 30px;
          font-weight: 900;
          line-height: 30px;
          transition: all ease 0.4s;
          @include media-breakpoint-down(xs) {
            font-size: 26px;
          }
          &:before {
            content: "";
            width: 0px;
            transition: all ease 0.4s;
          }
        }
        &.current-menu-item, &:hover {
          a {
            padding-left: 50px;
            transition: all ease 0.4s;
            color: $yellow;
            &:before {
              content: "";
              background-color: $yellow;
              height: 2px;
              width: 45px;
              display: block;
              position: absolute;
              left: 0;
              top: 15px;
              transition: all ease 0.4s;
            }
          }
        }
      }
    }

  }

  .item-socials-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    .item-socials {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      a {
        font-size: 25px;

        &:not(:last-child) {
          margin-right: 25px;
        }
      }

      @include media-breakpoint-down(xs) {
        margin-bottom: 15px;

        a {
          font-size: 20px;
        }
      }
    }
  }
}

.home {
  #header {
    width: 100%;
    position: fixed;
    z-index: 10;
    transition: all ease 0.8s;
    &.sticky {
      background-color: #0e1a49;
      .btn-menu {

        &.btn-menu-b:before {
          content: "";
          background-image: url('../img/icon/menu-c.png');
        }

        .item-burger > span{
          background-color: #e8e8e8;
        }

      }
    }
  }
}

.copy-menu {
  position: absolute;
  bottom: 30px;
  color: #a6a6a6;
  font-size: 12px;
  font-family: "Frutiger";
  @include media-breakpoint-down(md) {
    display: none;
  }
  a {
    bottom: 30px;
    color: #a6a6a6;
    font-size: 12px;
    font-weight: 600;
    font-family: "Frutiger";
  }
}