/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: $font-family-default;
  color: $default-color;
  font-size: $default-font-size;
  line-height: $default-line-height;
  font-weight: $default-font-weight;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  @each $property, $value in $default-titles {
    #{$property}: $value;
  }
}

h1 {
  color: #000000;
  font-family: "Frutiger";
  font-size: 64px;
  font-weight: 900;
  line-height: 66px;
  @include media-breakpoint-down(md) {
    font-size: 34px;
    line-height: initial;
  }
}

h2 {
  color: $yellow;
  font-family: "Frutiger";
  font-size: 50px;
  font-weight: 900;
  line-height: 54px;
  @include media-breakpoint-down(md) {
    font-size: 28px;
    font-weight: 900;
    line-height: initial;
    text-align: center;
  }
  span {
    color: $blue;
  }
}

.bg-blue {
  h2 {
    span {
      color: white;
    }
  }
  .cms {
    color: white;
  }
}

button, input, textarea {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
}

img {
  max-width: initial;
  width: 100%;
}

a, img, span, button {
  display: inline-block;
}

a {
  color: $default-link-color;
  text-decoration: none;

  &:hover {
    color: $default-link-color;
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cms {
  color: #0e1a49;
  text-align: justify;
  font-family: "Arial";
  font-size: 16px;
  line-height: 26px;
}

.btn {
  background-color: $blue;
  border-color: $blue;
  color: white;
  font-family: "Arial";
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  min-width: 230px;
  padding: 20px 20px;
  text-align: center;
  z-index: 2;
  @include media-breakpoint-down(sm) {
    min-width: 160px;
    margin: auto;
    display: block;
    width: 260px;
  }
  &:hover {
    background-color: white;
    border-color: $blue;
    color: $blue;
  }
}

.only-desktop {
  display: block;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.only-mobile {
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.clearfix {
  clear: both;
  width: 100%;
}

.rel {
  position: relative;
}

.bg-blue-menu {
  background: #0e1a49;
  transition: all ease 0.4s;
}

.home .bg-blue-menu {
  background: initial;
  transition: all ease 0.4s;
}

.grid-6-col {
  &:before {
    content: "";
    background-size: 16.6667% 100%;
    background-image: linear-gradient(to right, #0e1a49 0%, transparent 1px, transparent 100%);
    border-right: 1px solid #8990ab;
    opacity: 0.1;
    //background-image: linear-gradient(to right, red 0%, transparent 1px, transparent 100%);
    //border-right: 1px solid red;
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0px;
    right: 0px;
  }
}

.bg-blue,
.newslist,
.intro {
  .grid-6-col {
    &:before {
      content: "";
      background-image: linear-gradient(to right, #f1f1f1 0%, transparent 1px, transparent 100%);
      border-right: 1px solid #848484;
      opacity: 0.1;
    }
  }
}

.scrollToTop {
  width: 50px;
  height:50px;
  background-color: #f9b300;
  border-radius: 100%;
  position: fixed;
  font-size: 24px;
  color: #fff;
  border: none;
  right: 20px;
  bottom: 45px;
  display: none;
  cursor: pointer;
  z-index: 2;
  img {
    margin-bottom: 6px;
    height: 20px;
  }
}

.section {
  @include media-breakpoint-down(md) {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}