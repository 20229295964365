/*
|--------------------
|      NEWS
|--------------------
*/

.load-more-container {

  min-height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  .loader-container {
    display: none;
    color: white;
  }
  .load-more {
    .btn {
      background-color: white;
      border-color: $blue;
      color: $blue;
      &:hover {
        background-color: $yellow;
        border-color: $yellow;
        color: white;
      }
    }
  }
}

#page-news-archive {
  background-color: $blue;
  .intro {
    position: relative;
    color: white;
    @include media-breakpoint-up(lg) {
    }
  }
  h1 {
    margin-left: 65px;
    color: white;
    font-family: "Frutiger";
    font-size: 64px;
    font-weight: 900;
    line-height: 64px;
    @include media-breakpoint-up(lg) {
      &:before {
        content: "";
        height: 1px;
        width: 55px;
        background-color: white;
        display: block;
        position: absolute;
        left: 0px;
        top: 26px;
      }
    }
    @include media-breakpoint-down(md) {
      font-size: 30px;
      line-height: initial;
      text-align: center;
      margin-left: 0;
    }
    span {
      color: $yellow;
    }
  }
}

.blog{
  .entete{
    @include media-breakpoint-down(md) {
      color: white;
      font-family: Arial;
      font-size: 16px;
      line-height: 26px;
      text-align:center;
    }
  }
}

#page-news-single {
  background-image: url('../img/global/actu-bg.png');
  background-position: top center;
  background-repeat: repeat-x;
  width: 100%;
  .back-link {
    &:hover {
      color: $yellow;
    }
  }
  .post-date {
    color: white;
    font-family: Arial;
    font-size: 12px;
    line-height: 21px;
  }
  h1.title-xl {
    color: white;
    font-family: "Frutiger";
    font-size: 64px;
    font-weight: 900;
    line-height: 56px;
    @include media-breakpoint-down(md) {
      font-size: 32px;
      line-height: initial;
    }
    @include media-breakpoint-down(sm) {
      font-size: 30px;
      line-height: initial;
    }
    span {
      color: $yellow;

    }
  }
  .bg-img-landscape-sm {
    min-height: 300px;
  }
  .cms {
    color: #a6a6a6;
    font-family: Arial;
    font-size: 16px;
    p {
      color: #a6a6a6;
      font-family: Arial;
      font-size: 16px;
    }
    h2 {
      color: $blue;
      font-family: "Frutiger";
      font-size: 50px;
      font-weight: 900;
      line-height: 54px;
      @include media-breakpoint-down(md) {
        font-size: 30px;
        line-height: 36px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 22px;
        line-height: 24px;
      }
      &:before {
        content: initial;
      }
    }
    strong, b {
      color: $blue;
      font-weight: bold;
      font-family: Arial;
      font-size: 16px;
    }
    ul {
      padding-bottom: 20px;
      li {
        padding-left: 20px;
        position: relative;
        font-family: Arial;
        font-size: 16px;
        &:before {
          content: "";
          height: 5px;
          width: 5px;
          background-color: #0e1a49;
          border-radius: 50%;
          display: block;
          float: left;
          position: absolute;
          left: 0;
          top: 10px;
        }
      }
    }
  }
}