/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/

/*
|
| Reset form elements
|-------------------------
*/
input, textarea, select {
  -webkit-appearance: none;
  border-radius: 0;

  &[type="checkbox"] {
    -webkit-appearance: checkbox;
  }

  &:focus {
    outline: none;
  }
}

select {
  background-image: url('../img/icon/bottom.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: calc(100% - 10px) center;
}

/*
|
| Gravity form bootstrap
|-------------------------
*/
.gravity-form-bootstrap {
  .gform_fields {
    @extend .row;

    input, textarea {
      width: 100%;
      background-color: transparent;
    }
  }
}

/*
|
| Materialize form
|-------------------
*/
.materialize-form {
  .materialize-form-group {
    position: relative;
    margin-bottom: 40px;

    label {
      position: absolute;
      top: -20px;
      left: 15px;

      color: #ffffff;
      font-family: Arial;
      font-size: 12px;
      letter-spacing: 1.2px;
      line-height: 21px;
      text-transform: uppercase;
    }
    input {
      border: none;
      border-bottom: 1px #fff solid;
      color: #fff;
      height: 25px;
    }

    &.gfield_error {
      label {
        top: -20px;
      }
    }

  }
}

/*
|
| Contact form
|---------------
*/
.contact-form {
  label {
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1.2px;
    line-height: 21px;
    text-transform: uppercase;
  }

  select {
    width: 100%;
    background-color: #0e1a49;
    margin: 5px 0;
    border: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    background-size: initial;
    background-image: url('../img/icon/Arrow-small-d.svg');
  }

  textarea {
    border: none;
    border-bottom: 1px #fff solid;
    height: 120px;
    padding: 5px 10px;
    color: #fff;

    &.placeholder_disabled {
      color: #fff;
    }
  }

  .gform_body {
    margin-bottom: 20px;
  }

  .gform_footer {
    text-align: right;
  }

  input[type="submit"] {
    position: relative;
    background-color: transparent;
    border: none;
    color: #fff;
    padding: 15px;
    transition: all 0.3s ease-out;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      color: #fff;
    }

  }

  .gform_confirmation_message {
    color: #fff;
  }

  .validation_message {
    margin-top: 5px;
    color: #BB0B0B;
    font-size: 14px;
  }
}

.validation_error {
  margin-bottom: 30px;
  color: #BB0B0B !important;
}

#gform_ajax_spinner_1 {
  //display: none;
  margin-left: 10px;
  display: inline-block;
  width: 50px;
  height: 50px;
}